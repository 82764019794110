import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import "./dynamicCheckService.css";
import "react-phone-number-input/style.css";
import { Link, useLocation } from "react-router-dom";
import { Dialog, DialogTitle } from "@mui/material";
import CheckServiceCheckout from "../stripe/CheckServiceCheckout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLoading } from "../../_shared/LoadingContext";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { createPaymentIntent, getFreeSampale } from "../../_services/checkService";
import cookies from "js-cookie";
import checkServiceEn from '../../translation/checkServices-en.json'
import checkServiceEs from '../../translation/checkServices-es.json'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// Load Stripe outside the component to avoid reinitializing it on every render
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TEST_KEY);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY);


const SECRET_KEY = "6LcjP2spAAAAAPk0RY2IdFqOEDGz0OwxoXALjjbS";
const SITE_KEY = "6LcjP2spAAAAAGEdDTXPvavTKTSHVq2lo5jItpk8";

const DynamicCheckService = () => {
  const { t } = useTranslation();
  const [currentLanguageCode, setCurrentLanguageCode] = useState(cookies.get("i18next") || "en");
  const { checkService } = useParams();
  const [clientSecret, setClientSecret] = useState(null);
  const [freeSample, setFreeSample] = useState(null);
  const [show, setShow] = useState(false);
  const [valid_token, setValidToken] = useState([]);

  const { startLoading, stopLoading } = useLoading();
  const captchaRef = useRef(null);
  // State to force re-rendering of the ReCAPTCHA component
  const [captchaKey, setCaptchaKey] = useState(Date.now()); // Use timestamp as a key for re-rendering
  // Reload captcha when language changes
  useEffect(() => {
    const handleLanguageChange = () => {
      const newLanguageCode = cookies.get("i18next") || "en";
      if (newLanguageCode !== currentLanguageCode) {
        setCurrentLanguageCode(newLanguageCode);
        // Change the key to remount ReCAPTCHA
        setCaptchaKey(Date.now());
      }
    };
    // Listen for cookie changes or language changes (you could also use another event)
    const intervalId = setInterval(handleLanguageChange, 1000); // Polling for language changes (or use other detection)
    return () => clearInterval(intervalId); // Cleanup the interval on component unmount
  }, [currentLanguageCode]);
  const services = currentLanguageCode === 'en' ? checkServiceEn : checkServiceEs;

  const serviceData = useMemo(() => {
    return services[checkService] || {
      url: "premium-blacklist-checker",
      provider: "DashServices",
      serviceId: 3,
      price: 3.99,
      title: "Premium blacklist check",
      subtitle: "Verify if your phone is blacklisted or clean using its IMEI number. Ensure your device is not blacklisted due to loss, theft, or unpaid bills."

    };
  }, [checkService, services]);
  useEffect(() => {
    if (serviceData) {
      setShow(true)
      const fetchData = async () => {
        try {
          const body = {
            serviceId: serviceData.serviceId,
            imei: '000000000000000'
          }
          const response = await getFreeSampale(body)
          if (response) {
            setFreeSample(response?.result)
          }
        } catch (err) {
          console.error('API call failed:', err);
        }
      };

      fetchData(); // Call the API
    }
  }, [serviceData]);
  const validationSchema = (serviceId) => Yup.object({
    imei: serviceId === 48
      ? Yup.string().required('Phone number is required') // Validation when serviceId is 48
      : Yup.string()
        .required(t("ErrorField4"))
        .length(15, t("ErrorField41")), // Default validation when serviceId is not 48
    email: Yup.string()
      .email(t("ErrorField51"))
      .required(t("ErrorField5")),
  });

  // Example usage of validationSchema in a form
  const schema = validationSchema(serviceData.serviceId);

  const showFreeSample = () => {
    setShow(!show)
  }
  // Submit handler
  const handleSubmit = useCallback(
    async (values, { resetForm }) => {
      console.log('values: ', values);
      try {
        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        if (!token) {
          return toast.error(t("Sorry!! Verify you're not a bot"));
        }

        startLoading();
        const valid_token = await verifyToken(token);
        setValidToken(valid_token);

        if (valid_token[0]?.success) {
          const body = {
            serviceId: serviceData.serviceId,
            provider: serviceData.provider,
            price: serviceData.price,
            languageId: currentLanguageCode,
            name: serviceData.title,
            email: values.email,
            imei: values.imei,
          };

          const res = await createPaymentIntent(body);
          if (res?.clientSecret) {
            setClientSecret(res.clientSecret);
          } else {
            console.error("Unexpected response format:", res);
          }
        } else {
          toast.error("Verification failed. Please try again.");
        }
      } catch (error) {
        console.error("Error in handleSubmit:", error);
        toast.error("Something went wrong");
      } finally {
        stopLoading();
      }
    },
    [serviceData, currentLanguageCode, startLoading, stopLoading, t]
  );

  // Verify reCAPTCHA token
  const verifyToken = useCallback(async (token) => {
    try {
      const response = await Axios.post(`${process.env.REACT_APP_API_URL}/verifyToken`, {
        reCAPTCHA_TOKEN: token,
        Secret_Key: SECRET_KEY,
      });
      toast.success(t("IEMIS1"));
      return [response.data];
    } catch (error) {
      console.error("Error verifying token:", error);
      return [];
    }
  }, [t]);

  return (
    <div>
      <section className="banner-ImeiBlacklist py-md-2 pt-3 pt-md-5 pb-0 position-relative px-2">
        <div className="container py-2 pt-md-4">
          <div className="row justify-content-center p-0 p-md-3">
            <div className="col-md-6 col-12 px-md-5">
              <h1 className="fw-bold display-5 mt-md-5">{serviceData.title}</h1>
              <p className="imeiblacklist text-justify m-0 pt-4">{serviceData.subtitle}</p>
            </div>
            <div className="col-12 col-md-6 mt-4 mt-md-0 px-0">
              {!clientSecret && (
                <>
                  <Formik
                    initialValues={{ imei: "", email: "" }}
                    validationSchema={schema}
                    onSubmit={handleSubmit}

                  >
                    {({ handleChange, values, handleBlur }) => (
                      <Form className="bg-white rounded-4 p-1 p-md-4">
                        <div className="card unlock-card bg-transparent border-0 my-md-0 my-1">
                          <div className="card-body">
                            <h4 className="fw-semibold mb-4">{t('Ifmickf1')}</h4>
                            {serviceData && serviceData.serviceId == 48 ? (
                              <>
                                <div>
                                  <h6 className="fw-semibold small-font">{'Phone Number'}</h6>
                                  <div className="input-group mb-3 border rounded-input py-1 d-flex justify-content-between">
                                    <PhoneInput
                                      placeholder="Your mobile number "
                                      country={currentLanguageCode === "en" ? "us" : "es"}
                                      autoFormat
                                      value={values.imei}
                                      onChange={(imei) => handleChange("imei")(imei)}
                                      onBlur={handleBlur("imei")}
                                    />
                                  </div>
                                </div>
                                <ErrorMessage name="imei" component="div" className="error-message ps-5 ms-4" />

                              </>

                            ) : (
                              <>

                                <div>
                                  <p className="mb-0 fw-semibold">{t('Icunp6')}</p>
                                  <div className="input-group border d-flex align-items-center p-2 rounded mb-3 bg-white w-100">
                                    <label className="input-group-text bg-transparent border-0" htmlFor="imei">
                                      <img loading="lazy" src="/images/ph_phone-call (1).svg" alt="" />
                                      <span className="mx-2mx-0 text-secondary"> | </span>
                                    </label>
                                    <Field
                                      type="text"
                                      className="form-control border-0 py-1"
                                      placeholder={t("IEMIPC1")}
                                      aria-label={t("IEMIPC1")}
                                      id="imei"
                                      name="imei"
                                      onChange={handleChange}
                                    />
                                  </div>
                                </div>
                                <ErrorMessage name="imei" component="div" className="error-message ps-5 ms-4" />
                              </>
                            )}
                            <div>
                              <p className="mb-0 fw-semibold">Email</p>
                              <div className="input-group border d-flex align-items-center p-2 rounded mb-3 bg-white w-100">
                                <label className="input-group-text bg-transparent border-0" htmlFor="email">
                                  <img loading="lazy" src="/images/at-sign2.svg" alt="" />
                                  <span className="mx-2mx-0 text-secondary"> | </span>
                                </label>
                                <Field
                                  type="email"
                                  className="form-control border-0 py-1"
                                  placeholder={t("IEMIPem1")}
                                  aria-label="email"
                                  id="email"
                                  name="email"
                                  onChange={handleChange}
                                />
                              </div>
                            </div>
                            <ErrorMessage name="email" component="div" className="error-message ps-5 ms-4" />
                            <button type="submit" className="btn btn-lg mt-md-0 mt-2 w-100 unlock-btn">
                            {t('UsefullB1')}
                            </button>

                            <Link to className="btn btn-lg mt-md-3 mt-2 py-3 px-0 w-100 border rounded fs-6" onClick={showFreeSample}>
                              {show ? (
                               ` ${t('SSFSh1')}`
                              ) : (
                                `${t('SSFSh2')}`
                              )}
                            </Link>
                            {!show && (
                              <div className="row justify-content-center p-0 p-md-3 mt-3 mx-1 bg-light rounded ">
                                {freeSample && Object.entries(freeSample).map(([key, value], index) => (
                                  <div className="col-12 d-flex justify-content-between p-2 p-md-3 rounded" key={index}>
                                    <p className="mb-0 check-info-col1">{key}</p>
                                    <p className="mb-0 check-info-col2">{value}</p>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>

                        </div>
                        <div>

                        </div>
                        <ReCAPTCHA
                          key={captchaKey}
                          className="recaptcha"
                          sitekey={SITE_KEY}
                          ref={captchaRef}
                          hl={currentLanguageCode}
                        />
                      </Form>



                    )}
                  </Formik>

                </>
              )}
              {clientSecret && (
                <>
                  <p className="imeiblacklist text-justify m-0">You have pay {serviceData?.price}</p>

                  <Elements stripe={stripePromise} options={{ clientSecret: clientSecret, locale: currentLanguageCode, }}>
                    <CheckServiceCheckout formValue={122} customer={3} clientSecret={clientSecret} />
                  </Elements>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DynamicCheckService;
