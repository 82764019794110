import React, { forwardRef } from "react";
import "./testimonial.css";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import { Link } from "react-router-dom";
const Testimonial = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const code = cookies.get("i18next") || "en";
let trustbox=[]
let website;
let trustboxlang;

  if(code==='en'){
    website='https://www.trustpilot.com/review/www.unlockriver.com'
    trustboxlang='en-US'
      trustbox = [
      { name: "en1.jpg" },
      { name: "en2.jpg" },
      { name: "en3.jpg" }, 
      { name: "en4.jpg" },
    ];
  }else{
    trustboxlang='es-ES'
    website='https://es.trustpilot.com/review/www.unlockriver.com'
    trustbox = [
      { name: "es1.jpg" },
      { name: "es2.jpg" },
      { name: "es3.jpg" },
      { name: "es4.jpg" },
    ];
  }
  
  const images = trustbox?.map((e, index) => (
    <div key={index} className="testimonial-item bg-white rounded-4">
      <img
        className="img-fluid rounded-4"
        loading="lazy"
        src={`/images/trustbox/${e.name}`}
        alt=""
      />
    </div>
  ));
  return (
    <div ref={ref}>
      <section className="testimonial py-md-5 py-2">
        <div className="container">
          <h2 className="display-5 text-center">
            {t("TestH11")}{" "}
            <span className="fw-bold text-dark">{t("TestH12")}</span>{" "}
            {t("TestH13")}{" "}
            <span className="fw-bold text-dark">{t("TestH14")}</span>
          </h2>
          {/* <h2 className="text-center display-5">It <span className="fw-bold text-white">really</span> is. Have a look at what some our <span className="fw-bold text-white">customers</span> say</h2> */}
          <h2 className="text-center display-6">{t("TestH15")}</h2>
          <div className="row mt-5 mx-md-0 mx-2 px-md-5 justify-content-center ">
            <div className="col-md-12 col-lg-3 trustpilot rounded-4 d-flex flex-column justify-content-center ">
              <div>
                <img loading="lazy" src="/images/trustpilot-logo.png" alt="trustpilot-logo" />
                <div
                  className="trustpilot-widget"
                  data-locale={`${trustboxlang}`}
                  data-template-id="5419b6ffb0d04a076446a9af"
                  data-businessunit-id="544819a600006400057b1620"
                  data-style-height="40px"
                  data-style-width="100%"
                  data-theme="dark"
                >
                  <Link
                    to={website}
                    target="_blank"
                    rel="noopener"
                  >
                    Trustpilot
                  </Link>
                </div>
                <Link
                  className="btn btn-outline-light mt-4 px-3 py-3"
                  to={website}
                >
                  {t('TestB1')}
                </Link>
              </div>
            </div>
            <div className="col-md-12 col-lg-8 d-flex px-0 px-md-3 flex-wrap gap-3 pt-2 py-0 pt-md-4 pt-lg-0">
              {images}
              {/* <div className="testimonial-item bg-white">
                <img
                  className="img-fluid"
                  loading="lazy"
                  src="/images/trustbox/en1.jpg"
                  alt=""
                />
                <div className="testimonial-name d-flex gap-3 pb-3 border-bottom">
                  <img
                    loading="lazy"
                    className="rounded-circle"
                    src="/images/ali-khet.png"
                    width={57}
                    alt=""
                  />
                  <div>
                    <p className="fw-bold text-dark mb-1">Ali Khettouch</p>
                    <div>
                      <span className="me-md-5">2 reviews</span>
                      <span>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 9C7.50555 9 7.0222 8.85338 6.61108 8.57868C6.19995 8.30397 5.87952 7.91353 5.6903 7.45671C5.50108 6.99989 5.45157 6.49723 5.54804 6.01228C5.6445 5.52732 5.8826 5.08187 6.23223 4.73223C6.58187 4.3826 7.02732 4.1445 7.51228 4.04804C7.99723 3.95157 8.4999 4.00108 8.95671 4.1903C9.41353 4.37952 9.80397 4.69995 10.0787 5.11108C10.3534 5.5222 10.5 6.00555 10.5 6.5C10.4992 7.1628 10.2356 7.79822 9.76689 8.26689C9.29822 8.73556 8.6628 8.99921 8 9ZM8 5C7.70333 5 7.41332 5.08797 7.16665 5.2528C6.91997 5.41762 6.72771 5.65189 6.61418 5.92598C6.50065 6.20007 6.47095 6.50167 6.52882 6.79264C6.5867 7.08361 6.72956 7.35088 6.93934 7.56066C7.14912 7.77044 7.41639 7.9133 7.70737 7.97118C7.99834 8.02906 8.29994 7.99935 8.57403 7.88582C8.84812 7.77229 9.08238 7.58003 9.24721 7.33336C9.41203 7.08668 9.5 6.79667 9.5 6.5C9.4996 6.1023 9.34144 5.721 9.06022 5.43978C8.779 5.15856 8.3977 5.0004 8 5Z"
                            fill="#7B7B7B"
                          />
                          <path
                            d="M8.00001 15L3.78201 10.0255C3.7234 9.95081 3.66539 9.87564 3.60801 9.8C2.8875 8.85089 2.49826 7.69161 2.50001 6.5C2.50001 5.04131 3.07947 3.64236 4.11092 2.61091C5.14237 1.57946 6.54132 1 8.00001 1C9.4587 1 10.8576 1.57946 11.8891 2.61091C12.9205 3.64236 13.5 5.04131 13.5 6.5C13.5018 7.69107 13.1127 8.84982 12.3925 9.7985L12.392 9.8C12.392 9.8 12.242 9.997 12.2195 10.0235L8.00001 15ZM4.40601 9.1975C4.40701 9.1975 4.52301 9.3515 4.54951 9.3845L8.00001 13.454L11.455 9.379C11.477 9.3515 11.594 9.1965 11.5945 9.196C12.1831 8.42056 12.5012 7.47352 12.5 6.5C12.5 5.30653 12.0259 4.16193 11.182 3.31802C10.3381 2.47411 9.19348 2 8.00001 2C6.80653 2 5.66194 2.47411 4.81803 3.31802C3.97411 4.16193 3.50001 5.30653 3.50001 6.5C3.49896 7.47412 3.81689 8.42171 4.40601 9.1975Z"
                            fill="#7B7B7B"
                          />
                        </svg>{" "}
                        United State
                      </span>
                    </div>
                  </div>
                </div>
                <div className="ratings d-flex align-items-center justify-content-between my-3">
                  <div
                    className="trustpilot-widget"
                    data-locale="en-US"
                    data-template-id="53aa8807dec7e10d38f59f32"
                    data-businessunit-id="544819a600006400057b1620"
                    data-style-height="150px"
                    data-style-width="100%"
                    data-theme="light"
                  >
                    <a
                      href="https://www.trustpilot.com/review/www.unlockriver.com"
                      target="_blank"
                      rel="noopener"
                    >
                      Trustpilot
                    </a>
                  </div>
                </div>
                <div className="testimonial-content">
                  <h5 className="fw-bold">I dont usualy write reviews but…</h5>
                  <p>
                    I dont usualy write reviews but unlockriver desreve 5 stars.
                    They have unlcoked my my Note Ultra 20 in 5 days. They are
                    legit and I do recommend them 100%. CUSTOMER SERVICE IS TOP
                    NOTCH.
                  </p>
                </div>
              </div>

              <div className="testimonial-item bg-white p-3">
                <div className="testimonial-name d-flex gap-3 pb-3 border-bottom">
                  <img
                    loading="lazy"
                    className="rounded-circle"
                    src="/images/ali-khet.png"
                    width={57}
                    alt=""
                  />
                  <div>
                    <p className="fw-bold text-dark mb-1">Ali Khettouch</p>
                    <div>
                      <span className="me-md-5">2 reviews</span>
                      <span>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 9C7.50555 9 7.0222 8.85338 6.61108 8.57868C6.19995 8.30397 5.87952 7.91353 5.6903 7.45671C5.50108 6.99989 5.45157 6.49723 5.54804 6.01228C5.6445 5.52732 5.8826 5.08187 6.23223 4.73223C6.58187 4.3826 7.02732 4.1445 7.51228 4.04804C7.99723 3.95157 8.4999 4.00108 8.95671 4.1903C9.41353 4.37952 9.80397 4.69995 10.0787 5.11108C10.3534 5.5222 10.5 6.00555 10.5 6.5C10.4992 7.1628 10.2356 7.79822 9.76689 8.26689C9.29822 8.73556 8.6628 8.99921 8 9ZM8 5C7.70333 5 7.41332 5.08797 7.16665 5.2528C6.91997 5.41762 6.72771 5.65189 6.61418 5.92598C6.50065 6.20007 6.47095 6.50167 6.52882 6.79264C6.5867 7.08361 6.72956 7.35088 6.93934 7.56066C7.14912 7.77044 7.41639 7.9133 7.70737 7.97118C7.99834 8.02906 8.29994 7.99935 8.57403 7.88582C8.84812 7.77229 9.08238 7.58003 9.24721 7.33336C9.41203 7.08668 9.5 6.79667 9.5 6.5C9.4996 6.1023 9.34144 5.721 9.06022 5.43978C8.779 5.15856 8.3977 5.0004 8 5Z"
                            fill="#7B7B7B"
                          />
                          <path
                            d="M8.00001 15L3.78201 10.0255C3.7234 9.95081 3.66539 9.87564 3.60801 9.8C2.8875 8.85089 2.49826 7.69161 2.50001 6.5C2.50001 5.04131 3.07947 3.64236 4.11092 2.61091C5.14237 1.57946 6.54132 1 8.00001 1C9.4587 1 10.8576 1.57946 11.8891 2.61091C12.9205 3.64236 13.5 5.04131 13.5 6.5C13.5018 7.69107 13.1127 8.84982 12.3925 9.7985L12.392 9.8C12.392 9.8 12.242 9.997 12.2195 10.0235L8.00001 15ZM4.40601 9.1975C4.40701 9.1975 4.52301 9.3515 4.54951 9.3845L8.00001 13.454L11.455 9.379C11.477 9.3515 11.594 9.1965 11.5945 9.196C12.1831 8.42056 12.5012 7.47352 12.5 6.5C12.5 5.30653 12.0259 4.16193 11.182 3.31802C10.3381 2.47411 9.19348 2 8.00001 2C6.80653 2 5.66194 2.47411 4.81803 3.31802C3.97411 4.16193 3.50001 5.30653 3.50001 6.5C3.49896 7.47412 3.81689 8.42171 4.40601 9.1975Z"
                            fill="#7B7B7B"
                          />
                        </svg>{" "}
                        United State
                      </span>
                    </div>
                  </div>
                </div>
                <div className="ratings d-flex align-items-center justify-content-between my-3">
                  <div
                    className="trustpilot-widget"
                    data-locale="en-US"
                    data-template-id="53aa8807dec7e10d38f59f32"
                    data-businessunit-id="544819a600006400057b1620"
                    data-style-height="150px"
                    data-style-width="100%"
                    data-theme="light"
                  >
                    <a
                      href="https://www.trustpilot.com/review/www.unlockriver.com"
                      target="_blank"
                      rel="noopener"
                    >
                      Trustpilot
                    </a>
                  </div>
                </div>
                <div className="testimonial-content">
                  <h5 className="fw-bold">I dont usualy write reviews but…</h5>
                  <p>
                    I dont usualy write reviews but unlockriver desreve 5 stars.
                    They have unlcoked my my Note Ultra 20 in 5 days. They are
                    legit and I do recommend them 100%. CUSTOMER SERVICE IS TOP
                    NOTCH.
                  </p>
                </div>
              </div>
              <div className="testimonial-item bg-white p-3">
                <div className="testimonial-name d-flex gap-3 pb-3 border-bottom">
                  <img
                    loading="lazy"
                    className="rounded-circle"
                    src="/images/ali-khet.png"
                    width={57}
                    alt=""
                  />
                  <div>
                    <p className="fw-bold text-dark mb-1">Ali Khettouch</p>
                    <div>
                      <span className="me-md-5">2 reviews</span>
                      <span>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 9C7.50555 9 7.0222 8.85338 6.61108 8.57868C6.19995 8.30397 5.87952 7.91353 5.6903 7.45671C5.50108 6.99989 5.45157 6.49723 5.54804 6.01228C5.6445 5.52732 5.8826 5.08187 6.23223 4.73223C6.58187 4.3826 7.02732 4.1445 7.51228 4.04804C7.99723 3.95157 8.4999 4.00108 8.95671 4.1903C9.41353 4.37952 9.80397 4.69995 10.0787 5.11108C10.3534 5.5222 10.5 6.00555 10.5 6.5C10.4992 7.1628 10.2356 7.79822 9.76689 8.26689C9.29822 8.73556 8.6628 8.99921 8 9ZM8 5C7.70333 5 7.41332 5.08797 7.16665 5.2528C6.91997 5.41762 6.72771 5.65189 6.61418 5.92598C6.50065 6.20007 6.47095 6.50167 6.52882 6.79264C6.5867 7.08361 6.72956 7.35088 6.93934 7.56066C7.14912 7.77044 7.41639 7.9133 7.70737 7.97118C7.99834 8.02906 8.29994 7.99935 8.57403 7.88582C8.84812 7.77229 9.08238 7.58003 9.24721 7.33336C9.41203 7.08668 9.5 6.79667 9.5 6.5C9.4996 6.1023 9.34144 5.721 9.06022 5.43978C8.779 5.15856 8.3977 5.0004 8 5Z"
                            fill="#7B7B7B"
                          />
                          <path
                            d="M8.00001 15L3.78201 10.0255C3.7234 9.95081 3.66539 9.87564 3.60801 9.8C2.8875 8.85089 2.49826 7.69161 2.50001 6.5C2.50001 5.04131 3.07947 3.64236 4.11092 2.61091C5.14237 1.57946 6.54132 1 8.00001 1C9.4587 1 10.8576 1.57946 11.8891 2.61091C12.9205 3.64236 13.5 5.04131 13.5 6.5C13.5018 7.69107 13.1127 8.84982 12.3925 9.7985L12.392 9.8C12.392 9.8 12.242 9.997 12.2195 10.0235L8.00001 15ZM4.40601 9.1975C4.40701 9.1975 4.52301 9.3515 4.54951 9.3845L8.00001 13.454L11.455 9.379C11.477 9.3515 11.594 9.1965 11.5945 9.196C12.1831 8.42056 12.5012 7.47352 12.5 6.5C12.5 5.30653 12.0259 4.16193 11.182 3.31802C10.3381 2.47411 9.19348 2 8.00001 2C6.80653 2 5.66194 2.47411 4.81803 3.31802C3.97411 4.16193 3.50001 5.30653 3.50001 6.5C3.49896 7.47412 3.81689 8.42171 4.40601 9.1975Z"
                            fill="#7B7B7B"
                          />
                        </svg>{" "}
                        United State
                      </span>
                    </div>
                  </div>
                </div>
                <div className="ratings d-flex align-items-center justify-content-between my-3">
                  <div
                    className="trustpilot-widget"
                    data-locale="en-US"
                    data-template-id="53aa8807dec7e10d38f59f32"
                    data-businessunit-id="544819a600006400057b1620"
                    data-style-height="150px"
                    data-style-width="100%"
                    data-theme="light"
                  >
                    <a
                      href="https://www.trustpilot.com/review/www.unlockriver.com"
                      target="_blank"
                      rel="noopener"
                    >
                      Trustpilot
                    </a>
                  </div>
                </div>
                <div className="testimonial-content">
                  <h5 className="fw-bold">I dont usualy write reviews but…</h5>
                  <p>
                    I dont usualy write reviews but unlockriver desreve 5 stars.
                    They have unlcoked my my Note Ultra 20 in 5 days. They are
                    legit and I do recommend them 100%. CUSTOMER SERVICE IS TOP
                    NOTCH.
                  </p>
                </div>
              </div>

              <div className="testimonial-item bg-white p-3">
                <div className="testimonial-name d-flex gap-3 pb-3 border-bottom">
                  <img
                    loading="lazy"
                    className="rounded-circle"
                    src="/images/ali-khet.png"
                    width={57}
                    alt=""
                  />
                  <div>
                    <p className="fw-bold text-dark mb-1">Ali Khettouch</p>
                    <div>
                      <span className="me-md-5">2 reviews</span>
                      <span>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M8 9C7.50555 9 7.0222 8.85338 6.61108 8.57868C6.19995 8.30397 5.87952 7.91353 5.6903 7.45671C5.50108 6.99989 5.45157 6.49723 5.54804 6.01228C5.6445 5.52732 5.8826 5.08187 6.23223 4.73223C6.58187 4.3826 7.02732 4.1445 7.51228 4.04804C7.99723 3.95157 8.4999 4.00108 8.95671 4.1903C9.41353 4.37952 9.80397 4.69995 10.0787 5.11108C10.3534 5.5222 10.5 6.00555 10.5 6.5C10.4992 7.1628 10.2356 7.79822 9.76689 8.26689C9.29822 8.73556 8.6628 8.99921 8 9ZM8 5C7.70333 5 7.41332 5.08797 7.16665 5.2528C6.91997 5.41762 6.72771 5.65189 6.61418 5.92598C6.50065 6.20007 6.47095 6.50167 6.52882 6.79264C6.5867 7.08361 6.72956 7.35088 6.93934 7.56066C7.14912 7.77044 7.41639 7.9133 7.70737 7.97118C7.99834 8.02906 8.29994 7.99935 8.57403 7.88582C8.84812 7.77229 9.08238 7.58003 9.24721 7.33336C9.41203 7.08668 9.5 6.79667 9.5 6.5C9.4996 6.1023 9.34144 5.721 9.06022 5.43978C8.779 5.15856 8.3977 5.0004 8 5Z"
                            fill="#7B7B7B"
                          />
                          <path
                            d="M8.00001 15L3.78201 10.0255C3.7234 9.95081 3.66539 9.87564 3.60801 9.8C2.8875 8.85089 2.49826 7.69161 2.50001 6.5C2.50001 5.04131 3.07947 3.64236 4.11092 2.61091C5.14237 1.57946 6.54132 1 8.00001 1C9.4587 1 10.8576 1.57946 11.8891 2.61091C12.9205 3.64236 13.5 5.04131 13.5 6.5C13.5018 7.69107 13.1127 8.84982 12.3925 9.7985L12.392 9.8C12.392 9.8 12.242 9.997 12.2195 10.0235L8.00001 15ZM4.40601 9.1975C4.40701 9.1975 4.52301 9.3515 4.54951 9.3845L8.00001 13.454L11.455 9.379C11.477 9.3515 11.594 9.1965 11.5945 9.196C12.1831 8.42056 12.5012 7.47352 12.5 6.5C12.5 5.30653 12.0259 4.16193 11.182 3.31802C10.3381 2.47411 9.19348 2 8.00001 2C6.80653 2 5.66194 2.47411 4.81803 3.31802C3.97411 4.16193 3.50001 5.30653 3.50001 6.5C3.49896 7.47412 3.81689 8.42171 4.40601 9.1975Z"
                            fill="#7B7B7B"
                          />
                        </svg>{" "}
                        United State
                      </span>
                    </div>
                  </div>
                </div>
                <div className="ratings d-flex align-items-center justify-content-between my-3">
                  <div
                    className="trustpilot-widget"
                    data-locale="en-US"
                    data-template-id="53aa8807dec7e10d38f59f32"
                    data-businessunit-id="544819a600006400057b1620"
                    data-style-height="150px"
                    data-style-width="100%"
                    data-theme="light"
                  >
                    <a
                      href="https://www.trustpilot.com/review/www.unlockriver.com"
                      target="_blank"
                      rel="noopener"
                    >
                      Trustpilot
                    </a>
                  </div>
                </div>
                <div className="testimonial-content">
                  <h5 className="fw-bold">I dont usualy write reviews but…</h5>
                  <p>
                    I dont usualy write reviews but unlockriver desreve 5 stars.
                    They have unlcoked my my Note Ultra 20 in 5 days. They are
                    legit and I do recommend them 100%. CUSTOMER SERVICE IS TOP
                    NOTCH.
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
});

export default Testimonial;
