import React from "react";
import { useTranslation } from "react-i18next";
const Promotions = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="affiliate">
        <div className="container py-md-2 pt-2 trems-and-condition">
          <div className="row pt-md-5 justify-content-center pb-md-5 pb-3">
            <div className="col-12 col-md-11 px-4">
              <div className="promotion-promotion">
              <h2 className="py-md-4 py-3  tc-head fw-bold">{t("pnh1")} </h2>
                <h5 className="tc-title fw-bold">{t("pnp1")}</h5>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="py-2 tc-text">{t("pnp2")}</li>
                    <li  className="py-2 tc-text">{t("pnp3")}</li>
                    <li  className="py-2 tc-text">{t("pnp4")}</li>
                  </ul>
                
                <h5 className="tc-title fw-bold">{t("pnp5")}</h5>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="py-2 tc-text">{t("pnp6")}</li>
                    <li  className="py-2 tc-text">{t("pnp7")}</li>
                    <li  className="py-2 tc-text">{t("pnp8")}</li>
                    <li  className="py-2 tc-text">{t("pnp9")}</li>
                    <li  className="py-2 tc-text">{t("pnp10")}</li>
                    <li  className="py-2 tc-text">{t("pnp11")}</li>
                    <li  className="py-2 tc-text">{t("pnp12")}</li>
                  </ul>

                <h5 className="tc-title fw-bold">{t("pnp13")}</h5>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="py-2 tc-text">{t("pnp14")}</li>
                    <li  className="py-2 tc-text">{t("pnp15")}</li>
                  </ul>
                  <p className="tc-text my-2">{t("pnp16")}</p>

                <h5 className="tc-title pt-2 pb-3 fw-bold">{t("pnh2")}</h5>
                <h5 className="tc-title fw-bold">{t("pnp17")}</h5>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="py-2 tc-text">{t("pnp18")}</li>
                    <li  className="py-2 tc-text">{t("pnp19")}</li>
                    <li  className="py-2 tc-text">{t("pnp20")}</li>
                    <li  className="py-2 tc-text">{t("pnp21")}</li>
                  </ul>

                <h5 className="tc-title fw-bold">{t("pnp22")}</h5>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="py-2 tc-text">{t("pnp23")}</li>
                  </ul>

                <h5 className="tc-title fw-bold">{t("pnp24")}</h5>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="py-2 tc-text">{t("pnp25")}</li>
                  </ul>
                  
                <h5 className="tc-title fw-bold">{t("pnp26")}</h5>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="py-2 tc-text">{t("pnp27")}</li>
                    <li  className="py-2 tc-text">{t("pnp28")}</li>
                    <li  className="py-2 tc-text">{t("pnp29")}</li>
                    <li  className="py-2 tc-text">{t("pnp30")}</li>
                  </ul>
                  
                <h5 className="tc-title fw-bold">{t("pnp31")}</h5>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="py-2 tc-text">{t("pnp32")}</li>
                  </ul>
                  
                <h5 className="tc-title fw-bold">{t("pnp33")}</h5>
                  <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                    <li  className="py-2 tc-text">{t("pnp34")}</li>
                    <li  className="py-2 tc-text">{t("pnp35")}</li>
                    <li  className="py-2 tc-text">{t("pnp36")}</li>
                  </ul>
                  
                <h5 className="tc-title fw-bold"> {t("pnh3")}</h5>
                 <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                  <li  className="py-2 tc-text">{t("pnp37")}</li>
                  <li  className="py-2 tc-text">{t("pnp38")}</li>
                  <li  className="py-2 tc-text">{t("pnp39")}</li>
                  <li  className="py-2 tc-text">
                    {t("pnp40")}
                    <p className="tc-text my-md-1 my-2">{t("pnp41")}</p>
                    <p className="tc-text my-md-1 my-2">{t("pnp42")}</p>
                    <p className="tc-text my-md-1 my-2">{t("pnp43")}</p>
                  </li>
                  <li  className="py-2 tc-text">{t("pnp44")}</li>
                  <p className="tc-text my-md-1 my-2"> {t("pnp45")}</p>

                  <li  className="py-2 tc-text">{t("pnp46")}</li>
                  <p className="tc-text my-md-1 my-2">{t("pnp47")}</p>
                </ul>
                <h5 className="tc-title fw-bold">{t("pnh4")}{" "} </h5>
                <p className="tc-text my-md-1 my-2">{t("pnp48")}</p>
                <p className="tc-text my-md-1 my-2">{t("pnp49")}</p>
                <p className="tc-text my-md-1 my-2">{t("pnp50")}</p>

                <h5 className="tc-title fw-bold">{t("pnh5")} </h5>
                <p className="tc-text my-md-1 my-2">{t("pnp51")}</p>
                <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                  <li  className="py-2 tc-text">{t("pnp52")}</li>
                  <li  className="py-2 tc-text">{t("pnp53")}</li>
                </ul>
                <p className="tc-text my-md-1 my-2">{t("pnp54")}</p>
                <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                  <li  className="py-2 tc-text">{t("pnp55")} </li>
                  <li  className="py-2 tc-text">{t("pnp56")}</li>
                </ul>
                <p className="tc-text my-md-4 my-2">{t("pnp57")}</p>
                <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                  <li  className="py-2 tc-text">{t("pnp58")}</li>
                </ul>
                <p className="tc-text my-md-1 my-2">{t("pnp59")}</p>
                <p className="tc-text my-md-1 my-2">{t("pnp60")}</p>
                <ul className="py-2 ps-3 ps-md-3 ms-0 ms-md-3">
                  <li  className="py-2 tc-text">{t("pnp61")}</li>
                  <li  className="py-2 tc-text">{t("pnp62")}</li>
                  <li  className="py-2 tc-text">{t("pnp63")}</li>
                  <li  className="py-2 tc-text">{t("pnp64")}</li>
                </ul>
                <p className="tc-text my-md-1 my-2">{t("pnp65")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promotions;
