import './App.css';
import React, { useRef, Suspense, lazy, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import AppRouter from './AppRouter';
import Header from './components/header/Header';
import UsefulTools from './shared/usefulTools/usefulTools';
import Testimonial from './shared/testimonial/testimonial';
import Faq from './shared/faq/faq';
import ContactUs from './shared/contactUs/contactUs';
import Videos from './shared/videos/videos';
import HowitWork from './shared/howitWork/howitWork';
import Footer from './components/footer/Footer';
import Spinner from './_shared/spinner';
import { LanguageProvider } from './_shared/LanguageContext';
// const Header= lazy(() => import("./components/header/Header"))
// const UsefulTools= lazy(() => import("./shared/usefulTools/usefulTools"));
// const Testimonial= lazy(() => import("./shared/testimonial/testimonial"));
// const Faq= lazy(() => import("./shared/faq/faq"));
// const ContactUs= lazy(() => import("./shared/contactUs/contactUs"));
// const Videos= lazy(() => import("./shared/videos/Videos"));
// const HowitWork= lazy(() => import("./shared/howitWork/howitWork"));
// const Footer= lazy(() => import("./components/footer/Footer"));

function App() {
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t('webTitle') // Set the page title when the component mounts or when location changes
  }, [location.pathname, t]);
  console.log('Current route:', location.pathname);
  const componentRefs = {
    1: useRef(null),
    2: useRef(null),
    3: useRef(null),
    4: useRef(null),
  };

  const scrollToComponent = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const componentsToRender = [
    {
      component: <UsefulTools />,
      condition: ['/unlock-phone', '/samsung-acc-removal', '/iphone-fmi-check', '/icloud-unlock', '/imei-blacklist-checker', '/icloud-activation-lock-checker', '/original-network-identifier'].some(path => location.pathname.includes(path))
        || location.pathname.includes('/checkService')
    },
    {
      component: <HowitWork ref={componentRefs[2]} />,
      condition: ['/unlock-phone', '/samsung-acc-removal', '/icloud-unlock', '/imei-blacklist-checker', '/icloud-activation-lock-checker', '/original-network-identifier'].some(path => location.pathname.includes(path))
        // || location.pathname.includes('/checkService')
    },
    // Uncomment and update if necessary
    // { component: <Videos />, condition: '/unlock-phone' === location.pathname },
    {
      component: <Testimonial ref={componentRefs[3]} />,
      condition: ['/unlock-phone', '/samsung-acc-removal', '/iphone-fmi-check', '/icloud-unlock', '/imei-blacklist-checker', '/icloud-activation-lock-checker', '/original-network-identifier'].some(path => location.pathname.includes(path))
        || location.pathname.includes('/checkService')
    },
    {
      component: <Faq ref={componentRefs[1]} />,
      condition: ['/unlock-phone', '/samsung-acc-removal', '/iphone-fmi-check', '/icloud-unlock', '/imei-blacklist-checker', '/icloud-activation-lock-checker', '/original-network-identifier'].some(path => location.pathname.includes(path))
        || location.pathname.includes('/checkService')
    },
  ];

  return (
    <div>
      <LanguageProvider>
        <Spinner />
        {/* <Suspense fallback={<div >Loading...</div>}> */}
        <Header
          onComponent1ButtonClick={() => scrollToComponent(componentRefs[1])}
          onComponent2ButtonClick={() => scrollToComponent(componentRefs[2])}
          onComponent3ButtonClick={() => scrollToComponent(componentRefs[3])}
          onComponent4ButtonClick={() => scrollToComponent(componentRefs[4])}
        />
        <AppRouter />
        {componentsToRender.map(({ component, condition }, index) => (
          condition && <React.Fragment key={index}>{component}</React.Fragment>
        ))}
        {(location.pathname !== '/contact-us') && <ContactUs ref={componentRefs[4]} />}
        <Footer onComponent1ButtonClick={() => scrollToComponent(componentRefs[1])} />
        {/* </Suspense> */}
      </LanguageProvider>
    </div>

  )
}

export default App;
