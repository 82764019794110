import React, { useState } from "react";
import { useStripe, useElements, CardElement,PaymentElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useLoading } from "../../_shared/LoadingContext";
import "./CheckoutForm.css";
import { useTranslation } from "react-i18next";
import { checkImei } from "../../_services/unlockPhone";
import Cookies from "js-cookie";
const CheckoutIMEI = ({ IMEI, serviceId, clientSecret }) => {
  const { t } = useTranslation();
  const { startLoading, stopLoading } = useLoading();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [info, setInfo] = useState(null);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    startLoading();
    if (!stripe || !elements || !isNameValid() || !isEmailValid()) {
      stopLoading();
      return;
    }
    try {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements,
          billing_details: {
            name: name,
            email: email,
          },
        },
      });
      handlePaymentResult(result);
    } catch (error) {
      handleError(error);
    }
  };

  const handlePaymentResult = (result) => {
    stopLoading();
    if (result.error) {
      console.error(result.error.message);
      toast.error(result.error.message);
    } else {
      handleSuccessfulPayment(result);
    }
  };

  const handleSuccessfulPayment = async (result) => {
    const cookieTimeOut = 2;
    if (result.paymentIntent.status === "succeeded") {
      const res = await checkImei(IMEI, serviceId);
      if (res) {
        console.log(res);
        Cookies.set("imeiInfo", JSON.stringify(res), {
          expires: cookieTimeOut,
          sameSite: "None",
          secure: true,
        });
        setInfo(res);
        if (Cookies.get("imeiInfo")) {
          navigate(`/imei-status`, {
            state: {
              email: email,
            },
          });
        }
      } else {
        console.error("Unexpected response format:", res);
      }
    } else {
      toast.error("An error occurred during payment. Please try again.");
    }
  };

  const handleError = (error) => {
    console.error("Error in handleSubmit:", error);
    toast.error("An error occurred during payment. Please try again.");
    stopLoading();
  };

  const isNameValid = () => {
    const trimmedName = name.trim();
    if (!trimmedName) {
      setNameError(t("ContE1"));
      return false;
    } else {
      setNameError("");
      return true;
    }
  };
  const isEmailValid = () => {
    const trimmedEmail = email.trim();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!trimmedEmail) {
      setEmailError(t("ContE21")); // Set error for empty email
      return false;
    } else if (!emailRegex.test(trimmedEmail)) {
      setEmailError(t("ContE22")); // Set error for invalid email format
      return false;
    } else {
      setEmailError(""); // Clear error
      return true;
    }
  };
  
  return (
    <>
      <form onSubmit={handleSubmit}>
        <h2 className="pb-4 fw-semibold">
          You have to pay $3.99 for this service
        </h2>
        <div className="py-2">
          <input
            type="text"
            className="w-100 p-3 border rounded"
            placeholder={t("UnpF1")}
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {nameError && <div className="error">{nameError}</div>}
        </div>
        <div className="py-2">
          <input
            type="text"
            className="w-100 p-3 border rounded"
            placeholder={t("FormField5")}
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailError && <div className="error">{emailError}</div>}
        </div>
        <PaymentElement />
        <button
          type="submit"
          className="btn text-white border-none fs-5 w-100 rounded px-5 fw-bold py-4 mt-3 cus-payment-btn"
          style={{ background: "#6A5696" }}
        >
          {t("UnpCP3")}
        </button>
        <ToastContainer />
      </form>
    </>
  );
};

export default CheckoutIMEI;
