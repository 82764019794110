import React,{forwardRef} from 'react'
import "./howitWork.css";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const HowitWork =  forwardRef((props, ref)=> {
  const { t } = useTranslation();
  const location = useLocation();
  let content;
  console.log(location.pathname)
  if (location.pathname === "/samsung-acc-removal") {
    content = t('Howitworkp31');
  } else if (location.pathname === "/icloud-unlock") {
    content = t('Howitworkp32');
  } else {
    content = t('Howitworkp30');
  }
  return (
    <div ref={ref}>
        <section className="how-it-works py-5">
            <div className="container">
                <div className="row howitwrk-col py-md-5 rounded-5 mx-md-5 px-md-4 justify-content-center">
                    <div className="col-12 col-md-12 px-md-2">
                     <h2 className='text-center display-6 fw-semibold mb-2 py-3 py-md-1'>{t("HiwHeader")}</h2> 
                    </div>
                    <div className="col-12 col-md-3 col-lg-4  px-3">
                      <div className="text-center mb-0 mt-md-4 pt-md-4 pb-md-3">
                       
                        <img loading="lazy" className='start-0 bottom-0 img-fluid' src="/images/submit-device.png" alt="" height="170" width="250" />
                      </div>
                      <div className='py-1 ps-md-4 d-flex align-items-start gap-2'>
                       <div>
                        <h3 className="fw-semibold text-center fs-5">{t("Howitworkh1")}</h3>
                        <p className='text-justify small-font word-spacing-5 pt-3'>{t("Howitworkp1")}</p>
                        </div>
                      </div> 
                    </div>
                    
                    <div className="col-12 col-md-3 col-lg-4 px-3">
                      <div className="text-center mb-md-3 mt-3 pb-4">
                       
                        <img loading="lazy" className=' start-0 bottom-0 img-fluid' src="/images/submit-device2.png" alt="" height="170" width="250" />
                      </div>
                      <div className='py-1 ps-md-4 d-flex align-items-start gap-2'>
                       <div>
                        <h3 className="fw-semibold text-center fs-5">{t("Howitworkh2")}</h3>
                        <p className='text-justify small-font word-spacing-5 pt-3'>{t("Howitworkp2")}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-3 col-lg-4 px-3">
                      <div className=" text-center mb-md-3 mt-5">
                       
                        <img loading="lazy" className='start-0 bottom-0 img-fluid' src="/images/submit-device3.png" alt="" height="170" width="250" />
                      </div>
                      <div className='py-1 ps-md-4 d-flex align-items-start gap-2'>
                       <div>
                        <h3 className="fw-semibold text-center fs-5">{t("Howitworkh3")}</h3>
                        <p className="text-justify small-font word-spacing-5 pt-3">{content} </p>
                        </div>
                      </div>
                    </div>
                    
                </div>
            </div>
        </section>
    </div>
  )
})

export default HowitWork;